import React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarkerAlt, faClock, faCalendar } from "@fortawesome/free-solid-svg-icons"



import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"

class NewsItemTemplate extends React.Component {
  
  render() {
    const post = this.props.data.mdx
    const { previous, next } = this.props.pageContext
    const mapPointer = <FontAwesomeIcon icon={faMapMarkerAlt} />
const clock = <FontAwesomeIcon icon={faClock} />
const calendar = <FontAwesomeIcon icon={faCalendar} />
    return (
      <>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <Title>{post.frontmatter.title}</Title>
        <TimeDate
          style={{
            ...scale(-1 / 6),
          }}
        >
          <hr/>
          <TimeDateInner>
          {calendar} {post.frontmatter.date}
          </TimeDateInner>
          <TimeDateInner>
            {clock} {post.frontmatter.time}
          </TimeDateInner>
          <TimeDateInner>
          {mapPointer} {post.frontmatter.location}
        </TimeDateInner>
        <hr/>

        
        </TimeDate>
        <MDXRenderer>{post.body}</MDXRenderer>
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />

        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={`/news${previous.fields.slug}`} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={`/news${next.fields.slug}`} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
        <hr/>
        <div style={{
          textAlign: 'center',
        }}>
          <Link to="/news">Back to events</Link>
        </div>
      </>
    )
  }
}

export default NewsItemTemplate

export const pageQuery = graphql`
  query NewsBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        time: date(formatString:"HH:mm")
        description
        location
      }
    }
  }
`
const Title = styled.h1`
margin-top: ${rhythm(1.5)};
margin-bottom: ${rhythm(1.5)};
`
const TimeDate = styled.div`
display: block;
margin-bottom: ${rhythm(1)};
margin-top: ${rhythm(-0.25)};
hr {
  margin-bottom: 0;
  background: ${props => props.theme.colors.grey};
}
`
const TimeDateInner =styled.div`
color: ${props => props.theme.colors.secondaryDark};
svg {
  color: ${props => props.theme.colors.primary};
}
`